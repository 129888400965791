import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Link, Box, Image, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCheckCircle, FaExchangeAlt, FaSchool } from "react-icons/fa";
import { MdPerson, MdBusinessCenter } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				KnowledgeBridge
			</title>
			<meta name={"description"} content={"Мрієте розпочати власну справу, але не знаєте, з чого почати? Чи, можливо, ви хочете вдосконалити свої знання, щоб вивести існуючий бізнес на новий рівень? У KnowledgeBridge ми допомагаємо отримати знання, навички та підтримку, які потрібні для успіху в сучасному світі бізнесу."} />
			<meta property={"og:title"} content={"KnowledgeBridge"} />
			<meta property={"og:description"} content={"Мрієте розпочати власну справу, але не знаєте, з чого почати? Чи, можливо, ви хочете вдосконалити свої знання, щоб вивести існуючий бізнес на новий рівень? У KnowledgeBridge ми допомагаємо отримати знання, навички та підтримку, які потрібні для успіху в сучасному світі бізнесу."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link1" />
			<Override slot="text" />
			<Override slot="link3" />
		</Components.Header12>
		<Section padding="120px 0 120px 0" background="#052b43" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="70%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					<Strong
						font="--headline1"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Ваш шлях до швидкого старту бізнесу
					</Strong>
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Мрієте розпочати власну справу, але не знаєте, з чого почати? Чи, можливо, ви хочете вдосконалити свої знання, щоб вивести існуючий бізнес на новий рівень? У{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						KnowledgeBridge
					</Strong>
					{" "}ми допомагаємо отримати знання, навички та підтримку, які потрібні для успіху в сучасному світі бізнесу.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/kontakti"
						padding="10px 24px 10px 24px"
						color="#FFC400"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-secondary"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						lg-margin="0px 0px 0px 0px"
						sm-width="100%"
						hover-border-color="--color-orange"
						hover-color="--orange"
						hover-transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
					>
						Контакти
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="30%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"
					width="250px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
					srcSet="https://smartuploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13%3A01%3A50.804Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13%3A01%3A50.804Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13%3A01%3A50.804Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13%3A01%3A50.804Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13%3A01%3A50.804Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13%3A01%3A50.804Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13%3A01%3A50.804Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Box
			padding="80px 72px 80px 72px"
			background="linear-gradient(0deg,rgba(254, 253, 253, 0.72) 0%,rgba(248, 243, 243, 0.79) 100%),rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1575936123477-77ff4e911fa9?crop=entropy&cs=tinysrgb&fit=max&ixid=MXwyMDg4fDB8MHxwaG90by1wYWNrZWR8NXx8Y2FyfGVufDB8fHx8fDE2MzgzNjEzMjA&ixlib=rb-1.2.1&q=80&w=1080) bottom/cover no-repeat scroll padding-box"
			border-radius="24px"
			margin="0px 0px 64px 0px"
			sm-padding="60px 36px 60px 36px"
		>
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 'Source Sans Pro', sans-serif"
				>
					<Strong>
						Чому обирають KnowledgeBridge?
					</Strong>
				</Text>
				<Text margin="0px 0px 25px 0px" font="--base" color="--greyD2" md-text-align="center">
					Курс охоплює ключові аспекти бізнесу, допомагаючи вам упевнено розпочати або розвивати свою справу. Ось, що ми пропонуємо:
				</Text>
				<Box
					lg-flex-wrap="wrap"
					display="grid"
					grid-template-columns="repeat(3, 1fr)"
					grid-gap="16px"
					md-grid-template-columns="repeat(2, 1fr)"
					md-grid-gap="36px"
					sm-grid-template-columns="repeat(1, 1fr)"
					sm-grid-gap="8px"
					width="100%"
				>
					<Box
						sm-margin="0px 0px 30px 0px"
						sm-width="100%"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
						md-align-items="center"
					>
						<Icon
							category="fa"
							icon={FaCheckCircle}
							size="28px"
							margin="16px 0px 16px 0px"
							color="--orange"
							md-margin="0px 0px 16px 0px"
						/>
						<Text
							margin="0px 0px 18px 0px"
							color="--darkL2"
							font="--headline4"
							lg-text-align="left"
							md-font="--lead"
							md-text-align="center"
						>
							<Strong>
								Інтерактивний підхід до навчання
							</Strong>
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD2"
							font="--base"
							lg-text-align="left"
							md-text-align="center"
						>
							Поєднання відеоуроків, практичних завдань і реальних кейсів дозволяє не тільки вивчати теорію, а й бачити, як успішні бізнеси застосовують її на практиці.
						</Text>
					</Box>
					<Box
						sm-margin="0px 0px 30px 0px"
						sm-width="100%"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
						md-align-items="center"
					>
						<Icon
							category="fa"
							icon={FaCheckCircle}
							size="28px"
							margin="16px 0px 16px 0px"
							color="--orange"
							md-margin="0px 0px 16px 0px"
						/>
						<Text
							margin="0px 0px 18px 0px"
							color="--darkL2"
							font="--headline4"
							lg-text-align="left"
							md-font="--lead"
							md-text-align="center"
						>
							<Strong>
								Експертний супровід
							</Strong>
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD2"
							font="--base"
							lg-text-align="left"
							md-text-align="center"
						>
							Наші ментори — досвідчені підприємці та фахівці, які допоможуть вам подолати виклики на шляху до створення власної справи.
						</Text>
					</Box>
					<Box
						sm-margin="0px 0px 30px 0px"
						sm-width="100%"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
						md-align-items="center"
					>
						<Icon
							category="fa"
							icon={FaCheckCircle}
							size="28px"
							margin="16px 0px 16px 0px"
							color="--orange"
							md-margin="0px 0px 16px 0px"
						/>
						<Text
							margin="0px 0px 18px 0px"
							color="--darkL2"
							font="--headline4"
							lg-text-align="left"
							md-font="--lead"
							md-text-align="center"
						>
							Сертифікат про завершення
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD2"
							font="--base"
							lg-text-align="left"
							md-text-align="center"
						>
							Після курсу ви отримаєте сертифікат, який стане вагомим доказом ваших знань для інвесторів або роботодавців.
						</Text>
					</Box>
					<Box
						sm-margin="0px 0px 30px 0px"
						sm-width="100%"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
						md-align-items="center"
					>
						<Icon
							category="fa"
							icon={FaCheckCircle}
							size="28px"
							margin="16px 0px 16px 0px"
							color="--orange"
							md-margin="0px 0px 16px 0px"
						/>
						<Text
							margin="0px 0px 18px 0px"
							color="--darkL2"
							font="--headline4"
							lg-text-align="left"
							md-font="--lead"
							md-text-align="center"
						>
							<Strong>
								Реальні кейси
							</Strong>
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD2"
							font="--base"
							lg-text-align="left"
							md-text-align="center"
						>
							Аналізуємо успіхи та помилки компаній у різних галузях, щоб навчити вас уникати ризиків.
						</Text>
					</Box>
					<Box
						sm-margin="0px 0px 30px 0px"
						sm-width="100%"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
						md-align-items="center"
					>
						<Icon
							category="fa"
							icon={FaCheckCircle}
							size="28px"
							margin="16px 0px 16px 0px"
							color="--orange"
							md-margin="0px 0px 16px 0px"
						/>
						<Text
							margin="0px 0px 18px 0px"
							color="--darkL2"
							font="--headline4"
							lg-text-align="left"
							md-font="--lead"
							md-text-align="center"
						>
							Доступність для новачків
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD2"
							font="--base"
							lg-text-align="left"
							md-text-align="center"
						>
							Курс починається з азів і поступово вводить складніші теми.
						</Text>
					</Box>
					<Box
						sm-margin="0px 0px 30px 0px"
						sm-width="100%"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
						md-align-items="center"
					>
						<Icon
							category="fa"
							icon={FaCheckCircle}
							size="28px"
							margin="16px 0px 16px 0px"
							color="--orange"
							md-margin="0px 0px 16px 0px"
						/>
						<Text
							margin="0px 0px 18px 0px"
							color="--darkL2"
							font="--headline4"
							lg-text-align="left"
							md-font="--lead"
							md-text-align="center"
						>
							<Strong>
								Нетворкінг
							</Strong>
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD2"
							font="--base"
							lg-text-align="left"
							md-text-align="center"
						>
							Знайомтесь із однодумцями, знаходьте партнерів і діліться досвідом.
						</Text>
					</Box>
				</Box>
			</Box>
			<Link
				href="/services"
				text-decoration-line="initial"
				color="--light"
				font="--lead"
				hover-opacity="0.9"
				background="--color-indigo"
				border-radius="8px"
				padding="11px 24px 12px 24px"
				text-align="center"
				width="250px"
				margin="2rem auto 0px auto"
				display="inline-block"
			>
				{"        "}Дізнатися більше{"\n      "}
			</Link>
		</Box>
		<Section
			padding="90px 0 90px 0"
			sm-padding="60px 0 60px 0"
			background="rgba(0, 0, 0, 0) linear-gradient(120deg,white 0%,--color-lightD2 100%) 0% 0% /auto repeat scroll padding-box"
			color="--dark"
			md-padding="60px 0 60px 0"
			quarkly-title="Advantages/Features-27"
		>
			<Override slot="SectionContent" max-width="1220px" md-align-items="center" sm-min-width="280px" />
			<Box
				border-radius="24px"
				margin="0px 0px 80px 0px"
				md-margin="0px 0px 40px 0px"
				width="55%"
				md-width="80%"
				sm-width="100%"
			>
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline2"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					<Strong>
						Для кого цей курс?
					</Strong>
				</Text>
			</Box>
			<Box
				lg-flex-wrap="wrap"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-gap="36px"
				sm-grid-template-columns="repeat(1, 1fr)"
				sm-grid-gap="8px"
			>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Icon
						category="md"
						icon={MdPerson}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--orange"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline4"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Підприємців-початківців
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Реалізуйте свою бізнес-ідею з підтримкою експертів.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Icon
						category="md"
						icon={MdBusinessCenter}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--orange"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline4"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Власників малого бізнесу
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Вдоскональте управління й масштабуйтесь.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Icon
						category="fa"
						icon={FaExchangeAlt}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--orange"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline4"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Тих, хто змінює кар’єру
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Освоюйте бізнес із нуля.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Icon
						category="fa"
						icon={FaSchool}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--orange"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline4"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Студентів і випускників
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Отримайте практичні навички для майбутньої кар’єри.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 16px 0px" font="--headline1" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Що ви навчитеся?
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="--lead">
					Відкрийте для себе нові навички, які допоможуть вам досягти своїх цілей!
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="20px 30px 20px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline4">
							Планування та стратегія
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="left" font="--base">
							<Strong>
								Чому це важливо:
							</Strong>
							{" "}Бізнес-план — це ваш дорожній маршрут до успіху. Він дозволяє структурувати цілі, врахувати ризики та знайти фінансування.
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="center" font="--base">
							<Strong>
								Зміст:
							</Strong>
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Як знайти бізнес-ідею та перевірити її життєздатність
							<br />
							<br />
							Розробка ефективного бізнес-плану
							<br />
							<br />
							Постановка SMART-цілей
							<br />
							<br />
							Проведення аналізу ринку та конкурентів
						</Text>
						<Link
							href="/services"
							text-decoration-line="initial"
							color="--dark"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Дізнатися більше
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="20px 30px 20px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline4">
							<Strong>
								Фінансова грамотність
							</Strong>
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="left" font="--base">
							<Strong>
								Чому це важливо:
							</Strong>
							{" "}Фінансова стабільність є основою будь-якого успішного бізнесу. Ми навчимо вас аналізувати фінансові показники.
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="center" font="--base">
							<Strong>
								Зміст:
							</Strong>
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Основи бухгалтерії: прибуток, збитки, грошовий потік
							<br />
							<br />
							Бюджетування та фінансове прогнозування
							<br />
							<br />
							Як отримати фінансування: гранти, кредити, інвестиції
							<br />
							<br />
							Податкове планування та юридичні аспекти
						</Text>
						<Link
							href="/services"
							text-decoration-line="initial"
							color="--dark"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Дізнатися більше
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="20px 30px 20px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline4">
							Основи маркетингу
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="left" font="--base">
							<Strong>
								Чому це важливо:
							</Strong>
							{" "}Найкращий продукт чи послуга потребують грамотного маркетингу, щоб знайти свого клієнта.
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="center" font="--base">
							<Strong>
								Зміст:
							</Strong>
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Створення бренду та визначення його ідентичності
							<br />
							<br />
							Розробка маркетингових кампаній
							<br />
							<br />
							Робота з цифровими інструментами: SMM, SEO, email-маркетинг
							<br />
							<br />
							Вимірювання ефективності через ключові показники (KPI)
						</Text>
						<Link
							href="/services"
							text-decoration-line="initial"
							color="--dark"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Дізнатися більше
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="20px 30px 20px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline4">
							Лідерство та управління командою
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="left" font="--base">
							<Strong>
								Чому це важливо:
							</Strong>
							{" "}Ефективний лідер мотивує команду й сприяє досягненню цілей. Навчіться створювати позитивну атмосферу.
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="center" font="--base">
							<Strong>
								Зміст:
							</Strong>
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Принципи сучасного лідерства
							<br />
							<br />
							Як наймати та утримувати талановитих співробітників
							<br />
							<br />
							Управління командою, вирішення конфліктів
							<br />
							<br />
							Побудова навичок комунікації
						</Text>
						<Link
							href="/services"
							text-decoration-line="initial"
							color="--dark"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Дізнатися більше
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="20px 30px 20px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline4">
							Операційний менеджмент
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="left" font="--base">
							<Strong>
								Чому це важливо:
							</Strong>
							{" "}Ефективність у щоденній роботі дозволяє оптимізувати витрати й збільшити прибутки.
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="center" font="--base">
							<Strong>
								Зміст:
							</Strong>
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Як автоматизувати та спрощувати процеси
							<br />
							<br />
							Управління ланцюгами поставок
							<br />
							<br />
							Контроль якості та логістика
							<br />
							<br />
							Використання технологій для оптимізації операцій
						</Text>
						<Link
							href="/services"
							text-decoration-line="initial"
							color="--dark"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Дізнатися більше
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link4" />
			<Override slot="link2" />
			<Override slot="link6" />
			<Override slot="link5" />
			<Override slot="text" />
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});